import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/Seo"

export default ({
  children,
  title,
  description,
  keywords,
  image,
  url,
  domainVerfication,
  author, schema, courseSchema, videoSchema, educationSchema, breadCrumbSchema,newsArticle, webPageSchema, productSchema, isLanding=false
}) => {
  title = title || ""
  author = author || ""
  url = url || ""
  image = image || ""
  keywords = keywords || ""
  description = description || ""
  domainVerfication = domainVerfication || ""
  
  return (
    <div>
      <Seo
        title={title}
        description={description}
        keywords={keywords}
        image={image}
        url={url}
        domainVerfication={domainVerfication}
        author={author} schema={schema} courseSchema={courseSchema} videoSchema={videoSchema} educationSchema={educationSchema} breadCrumbSchema={breadCrumbSchema} newsArticle={newsArticle} webPageSchema={webPageSchema} productSchema={productSchema}
      />
      <Header isLanding={isLanding} />
      {children}
      <Footer isLanding={isLanding} />
    </div>
  )
}
