import { navigate } from "gatsby"

const isBrowser = typeof window !== `undefined`

const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {}

const setUser = user => (window.localStorage.gatsbyUser = JSON.stringify(user))

export const handleLogin = ({ username, password, redirect }) => {
  if (!isBrowser) return false

    const opts = {
        LoginId: username,
        Password: password
    }

    fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/login/', {
    method: 'post',
    headers: {  
        "Content-Type": "application/json",
    },
    body: JSON.stringify(opts)
    }).then(function(response) {
    return response.json();
    }).then(function(res) {
        if(res.success) {
          setUser(res.data);
          navigate(redirect);
        } else {
          navigate('/app/login/?success=false')
        }
    });

  return false
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()

  return !!user.LoginId
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  // console.log(`Ensuring the \`gatsbyUser\` property exists.`)
  setUser({})
  callback()
}