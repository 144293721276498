import React, { Component } from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import { Link, navigate } from "gatsby"
import Getstarted from '../components/getstarted.js'
import { getCurrentUser, logout } from "../utils/auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import '../styles/header.css'
class Header extends Component {

  state = {
    show: false,
    setShow: false
  }
  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = () => {
    this.setState({ show: true })
  }

  render() {

    const { LoginId, StudentName } = getCurrentUser();


    return (
      <div style={{ backgroundColor: "#ffffff" }}>
        {/* <div className="alert-bar">
        <p> Learn to build responsive websites in 2 days. <a href="https://programs.lpages.co/website-building-workshop/" target="_blank">Enroll Now</a></p>
      </div> */}
        <div className="container">
          <Navbar expand="lg" className="pl-0 pr-0">
            <Navbar.Brand><Link to="/"><img src="/images/logo.svg" style={{ width: `200px` }} alt="crampete logo" /></Link></Navbar.Brand>
            {!this.props.isLanding &&
              <React.Fragment>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ml-0">
                    <NavDropdown title="CRAM CLASS" id="basic-nav-dropdown">
                      <Link to="/cramclass/" className="dropdown-item">All Courses</Link>
                      <Link to="/courses/full-stack-web-development-online-course/" className="dropdown-item">Full Stack Web Development</Link>
                      <Link to="/courses/data-science-online-course/" className="dropdown-item">Data Science</Link>
                      <Link to="/courses/digital-marketing-offline-courses/" className="dropdown-item">Digital Marketing</Link>
                      <Link to="/courses/front-end-online-course/" className="dropdown-item">Front End Development</Link>
                      <Link to="/courses/back-end-online-course/" className="dropdown-item">Back End Development</Link>
                      <Link to="/courses/cloud-computing-online-course/" className="dropdown-item">Cloud Computing Program</Link>
                    </NavDropdown>
                    <Link to="/cramcode/" className="nav-link">CRAM CODE</Link>
                    <NavDropdown title="SUCCESS STORIES" id="success-stories">
                      <Link to="/reviews/" className="dropdown-item">Testimonials</Link>
                      <Link to="/case-studies/" className="dropdown-item">Case Studies</Link>
                    </NavDropdown>
                    <NavDropdown title="RESOURCES" id="basic-nav-dropdown">
                      <Link to="/blogs/" className="dropdown-item">Blog</Link>
                      {/* <Link to="/reviews" className="dropdown-item">Practice</Link> */}
                      <Link to="/newsroom/" className="dropdown-item">Newsroom</Link>
                      <Link to="/guides/" className="dropdown-item">Guides</Link>
                      <Link to="/videos/" className="dropdown-item">Video</Link>
                      <Link to="/webinars/" className="dropdown-item">Webinars</Link>
                      <Link to="/contests/" className="dropdown-item">Contests</Link>
                      <Link to="/hire-from-us/" className="dropdown-item">Hire From Us</Link>
                    </NavDropdown>
                  </Nav>
                  <Nav className="ml-auto mr-0 phoneAlign">
                    <FontAwesomeIcon icon={faPhone} size={16} flip="horizontal" />
                    <a href="tel:+91 93840 58989" className="nav-link">+91 93840 58989</a>
                  </Nav>
                  <Nav>
                    <Button onClick={this.handleShow} >Apply Now</Button>
                  </Nav>
                  {
                    LoginId &&
                    <Nav>
                      <NavDropdown title={`Hi, ${StudentName}`} id="basic-nav-dropdown">
                        <Link to="/app/profile" className="dropdown-item">Profile</Link>
                        <Link to="/"
                          onClick=
                          {event => {
                            event.preventDefault()
                            logout(() => navigate(`/app/login`))
                          }}
                          className="dropdown-item">Logout</Link>
                      </NavDropdown>
                    </Nav>
                  }
                </Navbar.Collapse>
              </React.Fragment>
            }
          </Navbar>
        </div>
        <Getstarted showPop={this.state.show} hidePop={this.handleClose} />
      </div>
    )
  }
}

export default Header


