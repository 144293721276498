import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { globalHistory as history } from '@reach/router'

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        image
      }
    }
  }
`

const Seo = ({ description, keywords, title, image, url, domainVerfication, author, schema, courseSchema = false, videoSchema = false, educationSchema = false, breadCrumbSchema = false, newsArticle = false, webPageSchema = false, productSchema=false }) => {
  const { location } = history
  
  const pageSchema = schema ? schema : `{"@context": "https://schema.org","@type": "Organization","name": "Crampete","url": "https://www.crampete.com/","logo": "https://www.crampete.com/images/logo.svg","alternateName": "Crampete","sameAs": ["https://www.facebook.com/crampete/","https://twitter.com/trycrampete","https://www.instagram.com/trycrampete/","https://www.linkedin.com/company/14505664/admin/","https://www.youtube.com/channel/UCs3sKIFU4o4AblGJHtknHIg"]}`;

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaAuthor = author || data.site.siteMetadata.author
        const metaUrl = url || data.site.siteMetadata.siteUrl
        const metaDomainVerfication = domainVerfication || ""
        const metaImage = image || data.site.siteMetadata.image
        const metaKeywords = keywords
          ? [keywords]
          : ["crampete", "full stack web development courses"]
        const canonical = location.href || metaUrl
        return (
          <Helmet
            title={metaTitle} link={
              canonical
                ? [
                    {
                      rel: "canonical",
                      href: canonical,
                    },
                  ]
                : []
            }
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `facebook-domain-verification`,
                content: metaDomainVerfication,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                property: `twitter:creator`,
                content: metaAuthor,
              },
              {
                property: `twitter:title`,
                content: metaTitle,
              },
              {
                property: `twitter:description`,
                content: metaDescription,
              },
              {
                property: `twitter:image`,
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : []
            )}
          >
          <script type="application/ld+json">
            {`${pageSchema}`}
          </script>
          {courseSchema && 
            <script type="application/ld+json">
              {`${courseSchema}`}
            </script>
          }
          {videoSchema && 
            <script type="application/ld+json">
              {`${videoSchema}`}
            </script>
          }
          {educationSchema && 
            <script type="application/ld+json">
              {`${educationSchema}`}
            </script>
          }
          {breadCrumbSchema && 
            <script type="application/ld+json">
              {`${breadCrumbSchema}`}
            </script>
          }
          {newsArticle && 
            <script type="application/ld+json">
              {`${newsArticle}`}
            </script>
          }
          {webPageSchema && 
            <script type="application/ld+json">
              {`${webPageSchema}`}
            </script>
          }
          {productSchema && 
            <script type="application/ld+json">
              {`${productSchema}`}
            </script>
          }
          </Helmet>
        )
      }}
    />
  )
}

export default Seo
