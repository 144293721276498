import React from "react"
import { Link, useStaticQuery } from "gatsby";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SingaporeFlag from "../../static/images/singapore-flag.png"
import IndiaFlag from "../../static/images/india-flag.png"
import UkFlag from "../../static/images/uk-flag.png"
import ZambiaFlag from "../../static/images/zambia-flag.png"

import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";


const Footer = ({ isLanding }) => {
  const data = useStaticQuery(graphql`
  query {
    allBlogDataset{
      edges {
        node {
          Id
          Title
          SlugURL
          FeaturedImageURL
          Excerpt
          PostedDate
        }
      }
    }
  }
  `)
  const relatedBlogs = data.allBlogDataset.edges.map((item) => item.node);

  return (

    !isLanding ?
      <section id="footer">
        <div className="container">
          <div className="row text-left">
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <ul className="list-unstyled quick-links footer-links">
                <li>
                  <h5>Company</h5>
                </li>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about/">About</Link>
                </li>
                <li>
                  <Link to="/reviews/">Reviews</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
                <li>
                  <a
                    href="https://app.crampete.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Login
                  </a>
                </li>
                <li>
                  <Link to="/franchise/">Own a Franchisee</Link>
                </li>
                <li>
                  <Link to="/hiring-partners/">Become a Hiring Partner</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <ul className="list-unstyled quick-links footer-links">
                <li>
                  <h5>Instructor Led Programs</h5>
                </li>
                <li>
                  <Link to="/courses/full-stack-web-development-online-course/">
                    Full Stack Web Developer
                  </Link>
                </li>
                <li>
                  <Link to="/courses/data-science-online-course/">Data Science</Link>
                </li>
                <li>
                  <Link to="/courses/digital-marketing-offline-courses/">Digital Marketing</Link>
                </li>
                <li>
                  <Link to="/courses/front-end-online-course/">
                    Front End Web Developer
                  </Link>
                </li>
                <li>
                  <Link to="/courses/back-end-online-course/">
                    Back End Web Developer
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <ul className="list-unstyled quick-links footer-links">
                <li>
                  <h5>Self Paced Programs</h5>
                </li>
                <li>
                  <Link to="/courses/python/">Python</Link>
                </li>
                <li>
                  <Link to="/courses/reactjs-online-course/">React JS</Link>
                </li>
                <li>
                  <Link to="/courses/mongodb-online-course/">MongoDB</Link>
                </li>
                <li>
                  <Link to="/courses/php-online-course/">PHP</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <ul className="list-unstyled quick-links footer-links">
                <li>
                  <h5>Contact Us</h5>
                </li>
                <li>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>India</div>
                      <a href="tel:+91 93840 58989">+91 93840 58989</a>
                    </div>
                    <img src={IndiaFlag} alt="India Flag" />
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>Singapore</div>
                      <a href="tel:+65 9428 7281">+65 9428 7281</a>
                    </div>
                    <img src={SingaporeFlag} alt="Singapore Flag" />
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>United Kingdom</div>
                      <a href="tel:+44 20 3598 2904">+44 20 3598 2904</a>
                    </div>
                    <img src={UkFlag} alt="United Kingdom Flag" />
                  </div>
                </li>
                <li>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>Zambia</div>
                      <a href="tel:+260 96 9616068">+260 96 9616068</a>
                    </div>
                    <img src={ZambiaFlag} alt="Zambia Flag" />
                  </div>
                </li>
                <li>
                  <ul className="list-unstyled list-inline social mt-4">
                    <li className="list-inline-item">
                      <a
                        href="https://www.facebook.com/crampete/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li className="list-inline-item ml-1">
                      <a
                        href="https://twitter.com/trycrampete"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li className="list-inline-item ml-1">
                      <a
                        href="https://www.instagram.com/trycrampete/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li className="list-inline-item ml-1">
                      <a
                        href="https://www.linkedin.com/company/14505664/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                    </li>
                    <li className="list-inline-item ml-1">
                      <a href="mailto:info@crampete.com">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </a>
                    </li>
                    <li className="list-inline-item ml-1">
                      <a
                        href="https://www.youtube.com/channel/UCs3sKIFU4o4AblGJHtknHIg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faYoutubeSquare} />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <hr className="hrLight" />

          <div className="py-3">
            <h5>RECENT BLOGS</h5>
            <div className="ftrBlgWrap">
              {
                relatedBlogs.filter(item => {
                  let endDate = new Date();
                  let startDate = moment(endDate).subtract(60, 'days').format('YYYY-MM-DD');
                  if (new Date(item.PostedDate).getTime() < endDate.getTime() && new Date(item.PostedDate).getTime() > new Date(startDate).getTime()) {
                    return item;
                  }
                }).map((recent) => {
                  return (
                    <p className="rctBlog"><a href={`/blogs/${recent.SlugURL}`}>{recent.Title}</a></p>
                  )
                })
              }
            </div>
          </div>
          <hr className="hrLight" />
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ul className="list-unstyled quick-links footer-links footer-links-left">
                <li>
                  <Link to="/terms-and-conditions">
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/refund-policy">
                    Refund/Cancellation Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ul className="list-unstyled quick-links footer-links footer-links-left">
                <li>
                  <Link to="/contact">
                    Singapore
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    Kuala Lumpur (Malaysia)
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    Chennai
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    Bangalore
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    Coimbatore
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    Raipur
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    Bhubaneswar
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 mt-sm-2">
              <p
                style={{
                  fontSize: "1rem",
                  fontWeight: "100",
                  color: "#9e9e9e !important",
                }}
              >
                Crampete Learning Private Limited ©2021 All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </section>
      : <></>
  )
}
export default Footer;
